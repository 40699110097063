<template>
  <v-parallax src="@/assets/images/pages/signin-bg.png" height="100%">
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col align-self="center" cols="12" sm="6">
          <Logo/>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined class="pa-3" min-height="500">
            <v-card-text class="pt-3">
              <h2>Sign in</h2>
              <p class="mt-0">New user? 
                <router-link to="/register">
                  <span>Create an account</span>
                </router-link>
              </p>

              <v-row>
                <v-col cols="12">
                  <v-form ref="loginForm" @submit.prevent="signinSubmitClick()">
                    <v-text-field
                      label="Email address"
                      type="email"
                      v-model="loginForm.email"
                      placeholder="adam@gmail.com"
                      :rules="[required('Email'), email('Email')]"
                      autocomplete="off"
                    ></v-text-field>
                    <v-btn
                      absolute
                      right
                      small
                      class="my-3 mx-3 rounded-pill"
                      color="primary"
                      type="submit"
                      :disabled="(loginForm.email!='') ? false : true"
                    >Continue</v-btn>
                  </v-form>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOGOUT } from "@/store/_actiontypes";
import validations from "@/utils/validations";
import Logo from "@/components/common/Logo.vue"
import * as firebaseService from '@/database/firebase'

export default {
  components: {
    Logo
  },
  data() {
    return {
      ...validations,
      loginForm: {
        email: "",
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      user: (state) => state.account.user,
    }),
  },
  created(){
    this.$vuetify.theme.dark = this.$tools.getLocal('settings').theme.int;
    this.handleLogOut();
  },
  mounted(){
    if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY")}`
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    }
  },
  methods: {
    ...mapActions("account", [LOGOUT]),

    signinSubmitClick(){
      if(this.validateEmail(this.loginForm.email)){
        this.$router.push({path: "/password", query: {email: this.loginForm.email}});
      }
    },
    handleLogOut(){
      if(this.user){
        let userdata = this.$tools.parseJwt(this.user.access_token);
        firebaseService.updateUserOnlineStatus(userdata.sub, false)
        
        let refresh_token = this.user.refresh_token;
        this.LOGOUT({ refresh_token }).then(
          (response) => {},
          (error) => error
        );
      }
    },
  },
};
</script>